<template>
	<div class="header-wrapper">
		<img class="logo" :src="logoUrl" @click="$router.push({ name: 'Home' })" />
		<img
			class="logo-xs"
			:src="logoUrlXs"
			@click="$router.push({ name: 'Home' })"
		/>
		<el-menu
			:default-active="activeIndex"
			class="top-menu-list"
			mode="horizontal"
			@select="menuItemClick"
		>
			<el-menu-item index="Home">首页</el-menu-item>

			<el-menu-item index="File">网盘</el-menu-item>
			<!--
			<li class="el-menu-item external-link">
        <a href="https://pan.qiwenshare.com/docs/" target="_blank">文档</a>
			</li>
      
      <li class="el-menu-item external-link">
        <a class="beta-link" href="https://netdisk.qiwenshare.com" target="_blank">
          <span class="beta-text">企业网盘</span>
          <el-tag class="beta-tag" type="warning" size="mini" effect="dark">内测</el-tag>
        </a>
      </li>
      -->

			<template v-if="isLogin">
				<el-submenu
					class="user-exit-submenu"
					index="User"
					v-if="screenWidth <= 768"
				>
					<!-- 操作栏收纳 -->
					<el-popover
						v-model="pwdPopoverVisible"
						placement="bottom"
						:trigger="screenWidth <= 768 ? 'manual' : 'hover'"
					>
						<template slot="title">
							<i class="el-icon-user-solid"></i>
							<span
								slot="reference"
								@click="pwdPopoverVisible = !pwdPopoverVisible"
								>{{ username }}</span
							>
						</template>
					</el-popover>
					<el-menu-item @click="exitButton()">退出</el-menu-item>
				</el-submenu>
				<template v-else>
					<!-- 为了和其他菜单样式保持一致，请一定要添加类名 el-menu-item -->
					<div class="el-menu-item exit" @click="exitButton()">退出</div>

					<div class="el-menu-item username" v-show="isLogin">
						<i class="el-icon-user-solid"></i>
						<!-- 操作栏收纳 -->
						<el-popover
							v-model="pwdPopoverVisible"
							placement="bottom"
							:trigger="screenWidth <= 768 ? 'manual' : 'hover'"
						>
							<span
								slot="reference"
								@click="pwdPopoverVisible = !pwdPopoverVisible"
								>{{ username }}</span
							>
							<!-- 密码弹窗 -->
							<PasswdDialog></PasswdDialog>
						</el-popover>
					</div>
				</template>
			</template>

			<!-- 开发环境 -->
			<el-menu-item class="login" index="Login" v-show="!isLogin"
				>登录</el-menu-item
			>

			<!-- 开发环境 -->
			<el-menu-item class="register" v-show="!isLogin" index="Register"
				>注册</el-menu-item
			>
		</el-menu>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PasswdDialog from './user/dialog/account/Diglog.vue'
export default {
	name: 'Header',
	components: {
		PasswdDialog
	},
	data() {
		return {
			logoUrl: require('_a/images/common/logo_header.png'),
			logoUrlXs: require('_a/images/common/logo_header_xs.png'),
			pwdPopoverVisible: false //  收纳栏是否显示
		}
	},
	computed: {
		...mapGetters(['isLogin', 'username']),
		// 当前激活菜单的 index
		activeIndex() {
			return this.$route.name || 'Home' //  获取当前路由名称
		},
		isProductEnv() {
			return (
				process.env.NODE_ENV !== 'development' &&
				location.origin === 'https://pan.qiwenshare.com'
			)
		},
		// 屏幕宽度
		screenWidth() {
			return this.$store.state.common.screenWidth
		}
	},
	watch: {
		/**
		 * 监听收纳栏状态
		 * @description 打开时，body 添加点击事件的监听
		 */
		pwdPopoverVisible(newValue) {
			if (newValue === true) {
				document.body.addEventListener('click', this.closePwdPopover)
			} else {
				document.body.removeEventListener('click', this.closePwdPopover)
			}
		}
	},
	methods: {
		// 奇文社区生产环境账户网址
		getAccountHref(path) {
			return `https://account.qiwenshare.com${path}?Rurl=${location.href}`
		},
		/**
		 * 退出登录
		 * @description 清除 cookie 存放的 token  并跳转到登录页面
		 */
		exitButton() {
			this.$message.success('退出登录成功！')
			this.$common.removeCookies(this.$config.tokenKeyName)
			this.$store.dispatch('getUserInfo').then(() => {
				this.$router.push({ name: 'Home' })
			})
		},
		/**
		 * 关闭收纳栏
		 */
		closePwdPopover(event) {
			if (!event.target.className.includes('el-icon-user-solid')) {
				this.pwdPopoverVisible = false
			}
		},
		menuItemClick(key) {
			if (key === 'exit') {
				this.exitButton()
			} else if (key === 'Login') {
				if (this.isProductEnv) {
					location.href = this.getAccountHref('/login/account')
				} else {
					this.$router.push({ name: key })
				}
			} else if (key === 'Register') {
				if (this.isProductEnv) {
					location.href = this.getAccountHref('/register')
				} else {
					this.$router.push({ name: key })
				}
			} else if (key === 'File') {
				this.$router.push({ name: key, query: { fileType: 0, filePath: '/' } })
			} else {
				this.$router.push({ name: key })
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '~_a/styles/varibles.styl';

.header-wrapper {
  width: 100%;
  padding: 0 20px;
  box-shadow: $tabBoxShadow;
  display: flex;

  .logo {
    margin: 14px 24px 0 24px;
    display: inline-block;
    height: 40px;
    cursor: pointer;
  }

  .logo-xs {
    display: none;
  }

  >>> .el-menu--horizontal {
    .el-menu-item:not(.is-disabled):hover {
      border-bottom-color: $Primary !important;
      background: $tabBackColor;
    }

    .external-link {
      padding: 0;
      a {
        display: inline-block;
        padding: 0 20px;
      }
      .beta-link {
        .beta-text {
          margin-right: 8px;
        }
      }
    }
  }

  .top-menu-list {
    flex: 1;

    .login, .register, .username, .exit, .user-exit-submenu {
      float: right;
    }
  }
}
</style>
