<template>
	<div class="select-column">
		<div class="text" @click="handleDialogOpen">
			<i class="el-icon-s-operation"></i>
			设置密码
		</div>
		<!-- 对话框 -->
		<el-dialog
			title="密码修改"
			:visible.sync="visible"
			:append-to-body="true"
			width="550px"
			@close="handleDialogClose"
		>
			<el-form :model="form" :rules="formRules" ref="passwdForm">
				<el-form-item label="输入新密码" prop="pass">
					<el-input
						prefix-icon="el-icon-my-lock"
						show-password
						v-model="form.pass"
						placeholder="确认密码（8-16位数字及字母）"
						type="password"
						maxlength="16"
					></el-input>
				</el-form-item>
				<el-form-item label="再次输入密码" prop="checkPass">
					<el-input
						prefix-icon="el-icon-my-lock"
						show-password
						v-model="form.checkPass"
						placeholder="确认密码（8-16位数字及字母）"
						type="password"
						maxlength="16"
						autocomplete="off"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleDialogClose">取 消</el-button>
				<el-button
					type="primary"
					:loading="sureBtnLoading"
					@click="handleDialogSure('passwdForm')"
					>确 定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { resetPassWd } from '_r/file.js'

export default {
	name: 'PasswdDialog',
	data() {
		let validatePass = (rule, value, callback) => {
			let reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/)
			if (value === '') {
				callback(new Error('请输入密码'))
			} else if (value.length < 8 || !reg.test(value)) {
				callback(new Error('请输入含数字和字母的8-16位密码'))
			} else {
				if (this.form.checkPass !== '') {
					this.$refs['passwdForm'].validateField('checkPass')
				}
				callback()
			}
		}
		let validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.form.pass) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}

		return {
			visible: false, //  对话框是否可见
			form: {
				pass: '',
				checkPass: ''
			},
			formRules: {
				pass: [{ required: true, validator: validatePass, trigger: 'blur' }],
				checkPass: [
					{ required: true, validator: validatePass2, trigger: 'blur' }
				]
			},
			sureBtnLoading: false
		}
	},
	methods: {
		handleDialogOpen() {
			this.visible = true
		},
		/**
		 * 重命名文件对话框 | 对话框关闭的回调
		 * @description 关闭对话框，重置表单
		 */
		handleDialogClose() {
			this.$refs['passwdForm'].resetFields()
			this.visible = false
		},
		handleDialogSure(formName) {
			this.sureBtnLoading = true
			this.$refs[formName].validate((valid) => {
				if (valid) {
					resetPassWd({
						passwd: this.form.pass,
						checkPasswd: this.form.checkPass
					})
						.then((res) => {
							this.sureBtnLoading = false
							if (res.success) {
								this.$message.success('密码设置成功')
								this.$refs[formName].resetFields()
								this.visible = false
								this.callback('confirm')
							} else {
								this.$message.error(res.message)
							}
						})
						.catch(() => {
							this.sureBtnLoading = false
						})
				} else {
					this.sureBtnLoading = false
					return false
				}
			})
		}
	}
}
</script>
<style lang="stylus" scoped>
@import '~_a/styles/varibles.styl';

.select-column {
  .text {
    padding-top 8px
    cursor pointer
    &:hover {
      color $Primary
    }
  }
}
</style>
