export default {
    state: {
        showUploadMask: false
    },
    mutations: {
        showUploadMask(state) {
            state.showUploadMask = !state.showUploadMask;
        },
    },
    actions: {}
}
